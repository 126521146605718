<template>
  <div class="industry">
    <!-- PC -->
    <div
      class="
        productTop
        hidden-xs-only
        wow
        animate__animated animate__fadeInDown
      "
    >
      <img src="../../assets/images/swiper/swiperItem1.png" alt="" />
    </div>
    <!-- mobile -->
    <div
      class="
        mobileProductTop
        hidden-sm-and-up
        wow
        animate__animated animate__fadeInDown
      "
    >
      <img src="../../assets/images/mobile/index/index_1.png" alt="" />
    </div>
    <div class="location hidden-xs-only">
        <div class="section wow animate__animated animate__fadeInLeft">
            <div class="tarbar">
                <span>您的位置：</span>
                <span @click="handleChange(1)">首页</span>
                <span>></span>
                <span class="newsCenter">行业方案</span>
            </div>
        </div>
    </div>
    <div class="industry_content">
      <div class="section">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="金融行业" name="1">
            <div class="first_content wow animate__animated animate__fadeIn">
              <div class="first_top">客户面临挑战</div>
              <div class="introduce">金融低时延业务场景主要集中在高频交易和高频结算。高频交易和高频结算主要集中在期货证券，通常一个单用户可以在1s内进行4笔交易，其对延时要求非常高。此类客户通常不要求一家供应商提供带保护的线路，而是向业界最低的时延提供商采购一条非保护线路，再向时延第二的供应商采购一条，自己做保护系统。</div>
              <div class="question">
                <div class="lefts">
                  <div class="item">
                    <div class="itemOne">
                      <div class="img"></div>
                      <div class="text">现有线路时延还未到极致低时延</div>
                    </div>
                  </div>
                  <div class="itemOnes">
                    <div class="itemOne">
                      <div class="img"></div>
                      <div class="text">线路稳定性差，中断后修复时间长</div>
                    </div>
                  </div>
                  <div class="itemTwos">
                    <div class="itemOne">
                      <div class="img"></div>
                      <div class="text">UDP传输丢包大</div>
                    </div>
                  </div>
                </div>
                <div class="rights">
                  <img class="hidden-xs-only" src="../../assets/images/industry/index_1.png" alt="">
                  <img class="hidden-sm-and-up" src="../../assets/images/industry/mobile_1.png" alt="">
                </div>
              </div>
              <div class="solve">
                <div class="titles">解决方案</div>
                <!-- pc -->
                <div class="solveContent hidden-xs-only">
                  <swiper :options="swiperOptionTwo" ref="mySwiper">
                    <swiper-slide class="swiper-slide" v-for="(item,index) in categoryListTwo" :key="index" style="position:relative;overflow:hidden;width:385px;"
                    >
                      <img :src="item.imgSrc"/>
                      <div class="itemTitles">
                        <div class="itemTitle_title">
                          <div class="titlesss">{{item.titles}}</div>
                        </div>
                      </div>
                      <div class="ItemContent">
                        <div class="box">
                          <div class="oneContent">{{ item.title }}</div>
                        </div>
                      </div>
                    </swiper-slide>
                  </swiper>
                  <div class="swiper-button-prev" slot="button-prev">
                    <div>
                      <img class="arrowImg" src="../../assets/images/industry/left.png" alt="">
                    </div>
                  </div>
                  <div class="swiper-button-next" slot="button-next">
                    <div>
                      <img class="arrowImg" src="../../assets/images/industry/right.png" alt="">
                    </div>
                  </div>
                </div>
                <!-- 手机 -->
                <div class="solveContent hidden-sm-and-up">
                  <swiper :options="swiperMobileOptionTwo" ref="mySwiper">
                    <swiper-slide class="swiper-slide" v-for="(item,index) in categoryListTwo" :key="index" style="position:relative;overflow:hidden;width:385px;"
                    >
                      <img :src="item.imgSrc"/>
                      <div class="itemTitles">
                        <div class="itemTitle_title">
                          <div class="titlesss">{{item.titles}}</div>
                        </div>
                      </div>
                      <div class="ItemContent">
                        <div class="box">
                          <div class="oneContent">{{ item.title }}</div>
                        </div>
                      </div>
                    </swiper-slide>
                  </swiper>
                  <div class="swiper-button-prev" slot="button-prev">
                    <div>
                      <img class="arrowImg" src="../../assets/images/industry/left.png" alt="">
                    </div>
                  </div>
                  <div class="swiper-button-next" slot="button-next">
                    <div>
                      <img class="arrowImg" src="../../assets/images/industry/right.png" alt="">
                    </div>
                  </div>
                </div>
              </div>
              <div class="business">
                <div class="titles">商业价值</div>
                <div class="business_content">
                  <div class="business_content_left">
                    <div class="left_text">以更低的视线换取业务的增长（高频交易主要集中在证券和期货，通常系统可以在10μs（微秒）内进行1笔交易 按此计算：减少0.1MS（即100 μs），单一系统影响10笔以上的交易；减少0.5MS（即500 μs），单一系统影响50笔以上的交易</div>
                    <div>专人服务，提升体验</div>
                  </div>
                  <div class="business_content_right">
                    <img src="../../assets/images/industry/index_5.png" alt="">
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="制造业" name="2">
            <div class="second_content wow animate__animated animate__fadeIn">
              <div class="first_top">客户面临挑战</div>
              <div class="question">
                <div class="lefts">
                  <div class="item">
                    <div class="itemOne">
                      <div class="img"></div>
                      <div class="text">老厂区机房老旧</div>
                    </div>
                  </div>
                  <div class="itemOnes">
                    <div class="itemOne">
                      <div class="img"></div>
                      <div class="text">传统空调降温方式耗电，运维复杂</div>
                    </div>
                  </div>
                  <div class="itemTwos">
                    <div class="itemOne">
                      <div class="img"></div>
                      <div class="text">更新投资成本（CapEx）高、运营成本（OpEx）更高</div>
                    </div>
                  </div>
                  <div class="itemThrees">
                    <div class="itemThree">
                      <div class="img"></div>
                      <div class="text">扩展业务难度大</div>
                    </div>
                  </div>
                </div>
                <div class="rights">
                  <img class=" hidden-xs-only" src="../../assets/images/industry/index_1.png" alt="">
                  <img class="hidden-sm-and-up" src="../../assets/images/industry/mobile_1.png" alt="">
                </div>
              </div>
              <div class="solve">
                <div class="titles">解决方案</div>
                <!-- PC -->
                <div class="solveContent hidden-xs-only">
                  <swiper :options="swiperOption" ref="mySwiper">
                    <swiper-slide class="swiper-slide" v-for="(item,index) in categoryList" :key="index" style="position:relative;overflow:hidden;width:385px;"
                    >
                      <img :src="item.imgSrc"/>
                      <div class="itemTitles">
                        <div class="itemTitle_title">
                          <div class="titlesss">{{item.titles}}</div>
                        </div>
                      </div>
                      <div class="ItemContent">
                        <div class="box">
                          <div class="oneContent">{{ item.title }}</div>
                        </div>
                      </div>
                    </swiper-slide>
                  </swiper>
                  <div class="swiper-button-prev" slot="button-prev">
                    <div>
                      <img class="arrowImg" src="../../assets/images/industry/left.png" alt="">
                    </div>
                  </div>
                  <div class="swiper-button-next" slot="button-next">
                    <div>
                      <img class="arrowImg" src="../../assets/images/industry/right.png" alt="">
                    </div>
                  </div>
                </div>
                <!-- 手机 -->
                <div class="solveContent hidden-sm-and-up">
                  <swiper :options="swiperMobileOption" ref="mySwiper">
                    <swiper-slide class="swiper-slide" v-for="(item,index) in categoryList" :key="index" style="position:relative;overflow:hidden;width:385px;"
                    >
                      <img :src="item.imgSrc"/>
                      <div class="itemTitles">
                        <div class="itemTitle_title">
                          <div class="titlesss">{{item.titles}}</div>
                        </div>
                      </div>
                      <div class="ItemContent">
                        <div class="box">
                          <div class="oneContent">{{ item.title }}</div>
                        </div>
                      </div>
                    </swiper-slide>
                  </swiper>
                  <div class="swiper-button-prev" slot="button-prev">
                    <div>
                      <img class="arrowImg" src="../../assets/images/industry/left.png" alt="">
                    </div>
                  </div>
                  <div class="swiper-button-next" slot="button-next">
                    <div>
                      <img class="arrowImg" src="../../assets/images/industry/right.png" alt="">
                    </div>
                  </div>
                </div>
              </div>
              <div class="business">
                <div class="titles">商业价值</div>
                <div class="business_content">
                  <div class="business_content_left">
                    <div>降本增效</div>
                    <div>提升体验</div>
                    <div>节省运维成本和能源开销</div>
                    <div>有效保障企业未来业务拓展的空间</div>
                  </div>
                  <div class="business_content_right">
                    <img src="../../assets/images/industry/index_55.jpg" alt="">
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="零售业" name="3">
            <div class="third_content wow animate__animated animate__fadeIn">
              <div class="first_top">客户面临挑战</div>
              <div class="question">
                <div class="lefts">
                  <div class="item">
                    <div class="itemOne">
                      <div class="img"></div>
                      <div class="text">广域网线路多配置复杂</div>
                    </div>
                  </div>
                  <div class="itemOnes">
                    <div class="itemOne">
                      <div class="img"></div>
                      <div class="text">关键应用体验差</div>
                    </div>
                  </div>
                  <div class="itemTwos">
                    <div class="itemOne">
                      <div class="img"></div>
                      <div class="text">网络投资成本（CapEx）高、运营成本（OpEx）更高</div>
                    </div>
                  </div>
                  <div class="itemThrees">
                    <div class="itemThree">
                      <div class="img"></div>
                      <div class="text">业务网络需要安全隔离</div>
                    </div>
                  </div>
                </div>
                <div class="rights">
                  <img class="hidden-xs-only" src="../../assets/images/industry/index_1.png" alt="">
                  <img class="hidden-sm-and-up" src="../../assets/images/industry/mobile_1.png" alt="">
                </div>
              </div>
              <div class="solve">
                <div class="titles">解决方案</div>
                <!-- PC -->
                <div class="solveContent hidden-xs-only">
                  <swiper :options="swiperOptionOne" ref="mySwiperOne">
                    <swiper-slide class="swiper-slide" v-for="(item,index) in categoryListOne" :key="index" style="position:relative;overflow:hidden;width:385px;"
                    >
                      <img :src="item.imgSrc"/>
                      <div class="itemTitles">
                        <div class="itemTitle_title">
                          <div class="titlesss">{{item.titles}}</div>
                        </div>
                      </div>
                      <div class="ItemContent">
                        <div class="box">
                          <div class="oneContent">{{ item.title }}</div>
                        </div>
                      </div>
                    </swiper-slide>
                  </swiper>
                  <div class="swiper-button-prev" slot="button-prev">
                    <div>
                      <img class="arrowImg" src="../../assets/images/industry/left.png" alt="">
                    </div>
                  </div>
                  <div class="swiper-button-next" slot="button-next">
                    <div>
                      <img class="arrowImg" src="../../assets/images/industry/right.png" alt="">
                    </div>
                  </div>
                </div>
                <!-- 手机 -->
                <div class="solveContent hidden-sm-and-up">
                  <swiper :options="swiperMoblieOptionOne" ref="mySwiperOne">
                    <swiper-slide class="swiper-slide" v-for="(item,index) in categoryListOne" :key="index" style="position:relative;overflow:hidden;width:385px;"
                    >
                      <img :src="item.imgSrc"/>
                      <div class="itemTitles">
                        <div class="itemTitle_title">
                          <div class="titlesss">{{item.titles}}</div>
                        </div>
                      </div>
                      <div class="ItemContent">
                        <div class="box">
                          <div class="oneContent">{{ item.title }}</div>
                        </div>
                      </div>
                    </swiper-slide>
                  </swiper>
                  <div class="swiper-button-prev" slot="button-prev">
                    <div>
                      <img class="arrowImg" src="../../assets/images/industry/left.png" alt="">
                    </div>
                  </div>
                  <div class="swiper-button-next" slot="button-next">
                    <div>
                      <img class="arrowImg" src="../../assets/images/industry/right.png" alt="">
                    </div>
                  </div>
                </div>
              </div>
              <div class="business">
                <div class="titles">商业价值</div>
                <div class="business_content">
                  <div class="business_content_left">
                    <div>简化远端配置、提升整体运维效率</div>
                    <div>提升应用体验</div>
                    <div>节省广域网成本</div>
                    <div>有效保障企业出口安全</div>
                  </div>
                  <div class="business_content_right">
                    <img src="../../assets/images/industry/index_7.png" alt="">
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: this.$route.query.id,
      swiperOption: {
          loop: false, //是否循环轮播
          slidesPerView: 3,
          spaceBetween: 20,
          //左右切换
          navigation: {
             nextEl: ".swiper-button-next",
             prevEl: ".swiper-button-prev",
          },
      },
      swiperMobileOption: {
          loop: false, //是否循环轮播
          slidesPerView: 2,
          spaceBetween: 10,
          //左右切换
          navigation: {
             nextEl: ".swiper-button-next",
             prevEl: ".swiper-button-prev",
          },
      },
      swiperOptionOne: {
          loop: false, //是否循环轮播
          slidesPerView: 3,
          spaceBetween: 20,
          //左右切换
          navigation: {
             nextEl: ".swiper-button-next",
             prevEl: ".swiper-button-prev",
          },
      },
      swiperMoblieOptionOne: {
          loop: false, //是否循环轮播
          slidesPerView: 2,
          spaceBetween: 10,
          //左右切换
          navigation: {
             nextEl: ".swiper-button-next",
             prevEl: ".swiper-button-prev",
          },
      },
      swiperOptionTwo: {
          loop: false, //是否循环轮播
          slidesPerView: 3,
          spaceBetween: 20,
          //左右切换
          navigation: {
             nextEl: ".swiper-button-next",
             prevEl: ".swiper-button-prev",
          },
      },
      swiperMobileOptionTwo: {
          loop: false, //是否循环轮播
          slidesPerView: 2,
          spaceBetween: 10,
          //左右切换
          navigation: {
             nextEl: ".swiper-button-next",
             prevEl: ".swiper-button-prev",
          },
      },
      categoryList:[
        {
          titles:'模块方案',
          imgSrc:require('../../assets/images/industry/index_2.png'),
          title:'为客户优化老机房格局，整体部署EDAS边缘预制化模块方案'
        },
        {
          titles:'并入环控',
          imgSrc:require('../../assets/images/industry/index_3.png'),
          title:'将原有的机械空调并入环控，在极端天气时才开启使用'
        },
        {
          titles:'重新优化',
          imgSrc:require('../../assets/images/industry/index_4.png'),
          title:'气流管理重新优化，降低不必要的装修成本'
        },
        {
          titles:'拓展客户',
          imgSrc:require('../../assets/images/industry/index_2.png'),
          title:'利用新风制冷预支模块，搭建更灵活的模块，拓展客户IT机房可用空间'
        },
      ],
      categoryListOne:[
        {
          titles:'整体部署',
          imgSrc:require('../../assets/images/industry/index_2.png'),
          title:'为客户整体部署SD-WAN解决方案（23台设备），覆盖16个分销商、分公司、总部'
        },
        {
          titles:'广域网线路由',
          imgSrc:require('../../assets/images/industry/index_3.png'),
          title:'每家分公司广域网线路由双MPLS专线改造为MPLS + Internet宽带或者双Internet宽带线路'
        },
        {
          titles:'广域网体验',
          imgSrc:require('../../assets/images/industry/index_4.png'),
          title:'利用SD-WAN业务分段隔离、QoS、ZTP、SLA、集中图形化监控/管理提供完善的广域网体验'
        },
        {
          titles:'安全特性',
          imgSrc:require('../../assets/images/industry/index_2.png'),
          title:'利用SD-WAN安全特性（防火墙、IPS），防护出口安全'
        },
      ],
      categoryListTwo:[
        {
          titles:'金融专线',
          imgSrc:require('../../assets/images/industry/index_2.png'),
          title:'使用金融光网提供短时延金融专线时延低至18.5ms以内'
        },
        {
          titles:'主线中断',
          imgSrc:require('../../assets/images/industry/index_3.png'),
          title:'主线中断，提供备线18.7ms备线以及19.1ms第三路由备线'
        },
        {
          titles:'中断修复',
          imgSrc:require('../../assets/images/industry/index_4.png'),
          title:'中断修复缩短至4小时内，并有专人坐席确保衣易时段网络稳定'
        },
      ]
    };
  },
  created() {
    
  },
  mounted() {
  },
  methods:{
    handleChange(num) {
      this.$store.commit("changeNum", num);
      this.$router.push('/index')
    },
    handleClick(tab) {
      console.log(tab);
    }
  }
};
</script>
<style lang="scss">
.industry {
  padding-top: 92px;
  .productTop {
    img {
      width: 100%;
      height: 482px;
    }
  }
  .location {
    padding: 50px 0;
    .section {
        font-size: 18px;
        .tarbar {
            cursor: pointer;
            .newsCenter {
                color: #197dbe;
            }
        }
    }
  }
  .industry_content{
    .section {
      width: 1300px;
      .el-tabs {
        .el-tabs__header {
          padding: 0 50px;
          margin: 0 0 70px;
          line-height: 90px;
          .el-tabs__nav-wrap {
            .el-tabs__nav-scroll {
              .el-tabs__nav{
                .el-tabs__item {
                  font-size: 30px;
                  padding: 0 80px
                }
                #tab-1 {
                  padding-left: 0!important;
                }
                .el-tabs__active-bar {
                  height: 4px;
                  background: linear-gradient(to right, #25aae1, #197dbe);
                }
                .is-active {
                  display: inline-block;
                  background-image: linear-gradient(
                    to right,
                    #25a9e0,
                    #187aba
                  ); //最后三行为文字渐变CSS
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                }
              }
            }
          }
        }
        .el-tabs__content {
          .first_content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .first_top{
              text-align: center;
              margin-bottom: 35px;
              font-size: 36px;
              display: inline-block;
              background-image: linear-gradient(
                to right,
                #25a9e0,
                #187aba
              ); //最后三行为文字渐变CSS
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            .introduce {
              padding: 0 50px;
              font-size: 18px;
              line-height: 28px;
              letter-spacing: 1px;
            }
            .question {
              padding: 85px 50px;
              display: flex;
              .lefts {
                .item {
                  // background: linear-gradient(to top right, #25aae1, #197dbe);
                  width: 638px;
                  height: 141px;
                  position: relative;
                  .itemOne {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 638px;
                    height: 141px;
                    display: flex;
                    align-items: center;
                    .img {
                      background: url('../../assets/images/industry/item1.png') no-repeat center center;
                      background-size: cover;
                      width: 82px;
                      height: 82px;
                      margin: 0 28px;
                    }
                    .text {
                      font-size: 22px;
                      // color: #fff;
                      display: inline-block;
                      background-image: linear-gradient(
                        to right,
                        #25AAE1,
                        #197DBE
                      ); //最后三行为文字渐变CSS
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                }
                .item:hover {
                  background: linear-gradient(to top right, #25aae1, #197dbe);
                  .itemOne {
                    .img {
                      background: url('../../assets/images/industry/item1_selected.png') no-repeat center center;
                      background-size: cover;
                      transition: all 0.5s linear;
                    }
                    .text {
                      display: inline-block;
                      background-image: linear-gradient(
                        to right,
                        #fff,
                        #fff
                      ); //最后三行为文字渐变CSS
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                }
                .itemOnes {
                  // background: linear-gradient(to top right, #25aae1, #197dbe);
                  width: 638px;
                  height: 141px;
                  position: relative;
                  .itemOne {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 638px;
                    height: 141px;
                    display: flex;
                    align-items: center;
                    .img {
                      background: url('../../assets/images/industry/item1.png') no-repeat center center;
                      background-size: cover;
                      width: 82px;
                      height: 82px;
                      margin: 0 28px;
                    }
                    .text {
                      font-size: 22px;
                      // color: #fff;
                      display: inline-block;
                      background-image: linear-gradient(
                        to right,
                        #25AAE1,
                        #197DBE
                      ); //最后三行为文字渐变CSS
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                }
                .itemOnes:hover {
                  background: linear-gradient(to top right, #25aae1, #197dbe);
                  .itemOne {
                    .img {
                      background: url('../../assets/images/industry/item2_selected.png') no-repeat center center;
                      background-size: cover;
                      transition: all 0.5s linear;
                    }
                    .text {
                      display: inline-block;
                      background-image: linear-gradient(
                        to right,
                        #fff,
                        #fff
                      ); //最后三行为文字渐变CSS
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                }
                .itemTwos {
                  // background: linear-gradient(to top right, #25aae1, #197dbe);
                  width: 638px;
                  height: 141px;
                  position: relative;
                  .itemOne {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 638px;
                    height: 141px;
                    display: flex;
                    align-items: center;
                    .img {
                      background: url('../../assets/images/industry/item3.png') no-repeat center center;
                      background-size: cover;
                      width: 82px;
                      height: 82px;
                      margin: 0 28px;
                    }
                    .text {
                      font-size: 22px;
                      // color: #fff;
                      display: inline-block;
                      background-image: linear-gradient(
                        to right,
                        #25AAE1,
                        #197DBE
                      ); //最后三行为文字渐变CSS
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                }
                .itemTwos:hover {
                  background: linear-gradient(to top right, #25aae1, #197dbe);
                  .itemOne {
                    .img {
                      background: url('../../assets/images/industry/item3_selected.png') no-repeat center center;
                      background-size: cover;
                      transition: all 0.5s linear;
                    }
                    .text {
                      display: inline-block;
                      background-image: linear-gradient(
                        to right,
                        #fff,
                        #fff
                      ); //最后三行为文字渐变CSS
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                }
              }
              .rights {
                height: 423px;
                img {
                  height: 423px;
                }
              }
            }
            .solve {
              padding-bottom: 80px;
              .titles {
                padding: 20px 0 40px 0;
                width: 100%;
                text-align: center;
                font-size: 36px;
                display: inline-block;
                background-image: linear-gradient(
                  to right,
                  #25AAE1,
                  #197DBE
                ); //最后三行为文字渐变CSS
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
              .solveContent {
                // position: relative;
                .swiper-container {
                  width: 1200px;
                  .swiper-wrapper {
                        width: 385px!important;
                        .swiper-slide  {
                          position: relative;
                          overflow: hidden!important;
                          cursor: pointer;
                          img {
                              width: 100%!important;
                              height: 326px;
                              vertical-align: bottom;
                          }
                          .itemTitles {
                              position: absolute;
                              height: 60px;
                              left: 0;
                              width: 100%;
                              margin-top: -60px;
                              text-align: center;
                              transition: all 0.15s linear;
                              background: linear-gradient(to right, #25a9e0, #187aba);
                              opacity: .9;
                              .itemTitle_title {
                                position: relative;
                                .titlesss{
                                  position: absolute;
                                  top: 0;
                                  left: 50%;
                                  transform: translateX(-50%);
                                  color: #fff;
                                  font-size: 18px;
                                  line-height: 60px;
                                }
                              }
                          }
                          .ItemContent {
                              margin-top: 4px;
                              transition: all 0.4s linear;
                              position: absolute;
                              width: 100%;
                              height: 326px;
                              .box {
                                font-size: 20px;
                                position: absolute;
                                color: #fff;
                                left: 0;
                                top: -4px;
                                width: 100%;
                                height: 330px;
                                background:rgba(37, 169, 224, 0.8);
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                .oneContent {
                                  padding: 0 20px;
                                  line-height: 34px;
                                }
                              }
                          }
                        }
                        .swiper-slide:hover .ItemContent {
                          transform: translateY(-326px);
                          animation-play-state: running;
                        }
                        .swiper-slide:hover .itemTitles {
                          transform: translateY(60px);
                          animation-play-state: running;
                        }
                  }
                }
                .swiper-button-prev::after {
                  content: '';
                }
                .swiper-button-prev {
                    position: absolute;
                    top: 54%;
                    left: 0;
                    div {
                      width: 30px;
                      height: 60px;
                      .arrowImg {
                        width: 30px;
                        height: 60px;
                      }
                    }
                }
                .swiper-button-next::after {
                  content: '';
                }
                .swiper-button-next {
                    position: absolute;
                    top: 54%;
                    right: 0;
                    div {
                      width: 30px;
                      height: 60px;
                      img {
                        width: 30px;
                        height: 60px;
                      }
                    }
                }
              }
            }
            .business {
              padding: 0 50px;
              padding-bottom: 60px;
              .titles {
                padding: 0 0 40px 0;
                width: 100%;
                text-align: center;
                font-size: 36px;
                display: inline-block;
                background-image: linear-gradient(
                  to right,
                  #25AAE1,
                  #197DBE
                ); //最后三行为文字渐变CSS
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
              .business_content {
                display: flex;
                justify-content: space-between;
                .business_content_left {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  width: 572px;
                  .left_text {
                    text-indent: 2em;
                  }
                  div {
                    line-height: 34px;
                    font-size: 22px;
                  }
                }
                .business_content_right {
                  img{
                    width: 607px;
                    height: 461px;
                  }
                }
              }
            }
          }
          .second_content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .first_top{
              text-align: center;
              // margin-bottom: 35px;
              font-size: 36px;
              display: inline-block;
              background-image: linear-gradient(
                to right,
                #25a9e0,
                #187aba
              ); //最后三行为文字渐变CSS
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            .introduce {
              color: #666666;
              font-size: 30px;
              line-height: 50px;
              letter-spacing: 3px;
            }
            .question {
              padding: 85px 50px;
              display: flex;
              .lefts {
                height: 421px;
                .item {
                  // background: linear-gradient(to top right, #25aae1, #197dbe);
                  width: 638px;
                  height: 25%;
                  position: relative;
                  .itemOne {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 638px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    .img {
                      background: url('../../assets/images/industry/item1.png') no-repeat center center;
                      background-size: cover;
                      width: 82px;
                      height: 82px;
                      margin: 0 28px;
                    }
                    .text {
                      max-width: 500px;
                      font-size: 22px;
                      // color: #fff;
                      display: inline-block;
                      background-image: linear-gradient(
                        to right,
                        #25AAE1,
                        #197DBE
                      ); //最后三行为文字渐变CSS
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                }
                .item:hover {
                  background: linear-gradient(to top right, #25aae1, #197dbe);
                  .itemOne {
                    .img {
                      background: url('../../assets/images/industry/item1_selected.png') no-repeat center center;
                      background-size: cover;
                      transition: all 0.5s linear;
                    }
                    .text {
                      display: inline-block;
                      background-image: linear-gradient(
                        to right,
                        #fff,
                        #fff
                      ); //最后三行为文字渐变CSS
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                }
                .itemOnes {
                  // background: linear-gradient(to top right, #25aae1, #197dbe);
                  width: 638px;
                  height: 25%;
                  position: relative;
                  .itemOne {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 638px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    .img {
                      background: url('../../assets/images/industry/item2.png') no-repeat center center;
                      background-size: cover;
                      width: 82px;
                      height: 82px;
                      margin: 0 28px;
                    }
                    .text {
                      max-width: 500px;
                      font-size: 22px;
                      display: inline-block;
                      background-image: linear-gradient(
                        to right,
                        #25AAE1,
                        #197DBE
                      ); //最后三行为文字渐变CSS
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                }
                .itemOnes:hover {
                  background: linear-gradient(to top right, #25aae1, #197dbe);
                  .itemOne {
                    .img {
                      background: url('../../assets/images/industry/item2_selected.png') no-repeat center center;
                      background-size: cover;
                      transition: all 0.5s linear;
                    }
                    .text {
                      display: inline-block;
                      background-image: linear-gradient(
                        to right,
                        #fff,
                        #fff
                      ); //最后三行为文字渐变CSS
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                }
                .itemTwos {
                  // background: linear-gradient(to top right, #25aae1, #197dbe);
                  width: 638px;
                  height: 25%;
                  position: relative;
                  .itemOne {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 638px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    .img {
                      background: url('../../assets/images/industry/item3.png') no-repeat center center;
                      background-size: cover;
                      width: 82px;
                      height: 82px;
                      margin: 0 28px;
                    }
                    .text {
                      max-width: 500px;
                      font-size: 22px;
                      display: inline-block;
                      background-image: linear-gradient(
                        to right,
                        #25AAE1,
                        #197DBE
                      ); //最后三行为文字渐变CSS
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                }
                .itemTwos:hover {
                  background: linear-gradient(to top right, #25aae1, #197dbe);
                  .itemOne {
                    .img {
                      background: url('../../assets/images/industry/item3_selected.png') no-repeat center center;
                      background-size: cover;
                      transition: all 0.5s linear;
                    }
                    .text {
                      display: inline-block;
                      background-image: linear-gradient(
                        to right,
                        #fff,
                        #fff
                      ); //最后三行为文字渐变CSS
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                }
                .itemThrees {
                  // background: linear-gradient(to top right, #25aae1, #197dbe);
                  width: 638px;
                  height: 25%;
                  position: relative;
                  .itemThree {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 638px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    .img {
                      background: url('../../assets/images/industry/item3.png') no-repeat center center;
                      background-size: cover;
                      width: 82px;
                      height: 82px;
                      margin: 0 28px;
                    }
                    .text {
                      max-width: 500px;
                      font-size: 22px;
                      display: inline-block;
                      background-image: linear-gradient(
                        to right,
                        #25AAE1,
                        #197DBE
                      ); //最后三行为文字渐变CSS
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                }
                .itemThrees:hover {
                  background: linear-gradient(to top right, #25aae1, #197dbe);
                  .itemThree {
                    .img {
                      background: url('../../assets/images/industry/item3_selected.png') no-repeat center center;
                      background-size: cover;
                      transition: all 0.5s linear;
                    }
                    .text {
                      display: inline-block;
                      background-image: linear-gradient(
                        to right,
                        #fff,
                        #fff
                      ); //最后三行为文字渐变CSS
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                }
              }
              .rights {
                height: 423px;
                img {
                  height: 423px;
                  vertical-align: top;
                }
              }
            }
            .solve {
              padding-bottom: 80px;
              .titles {
                padding: 20px 0 40px 0;
                width: 100%;
                text-align: center;
                font-size: 36px;
                display: inline-block;
                background-image: linear-gradient(
                  to right,
                  #25AAE1,
                  #197DBE
                ); //最后三行为文字渐变CSS
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
              .solveContent {
                position: relative;
                .swiper-container {
                  width: 1200px;
                  .swiper-wrapper {
                        width: 385px!important;
                        .swiper-slide  {
                          position: relative;
                            .itemTitles {
                              position: absolute;
                              height: 60px;
                              left: 0;
                              width: 100%;
                              margin-top: -60px;
                              text-align: center;
                              transition: all 0.15s linear;
                              background: linear-gradient(to right, #25a9e0, #187aba);
                              opacity: .9;
                              .itemTitle_title {
                                position: relative;
                                .titlesss{
                                  position: absolute;
                                  top: 0;
                                  left: 50%;
                                  transform: translateX(-50%);
                                  color: #fff;
                                  font-size: 18px;
                                  line-height: 60px;
                                }
                              }
                          }
                          .ItemContent {
                              margin-top: 4px;
                              transition: all 0.4s linear;
                              position: absolute;
                              width: 100%;
                              height: 326px;
                              .box {
                                font-size: 20px;
                                position: absolute;
                                color: #fff;
                                left: 0;
                                top: -4px;
                                width: 100%;
                                height: 330px;
                                background:rgba(37, 169, 224, 0.8);
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                .oneContent {
                                  padding: 0 20px;
                                  line-height: 34px;
                                }
                              }
                          }
                            img {
                              width: 100%!important;
                              height: 326px;
                              vertical-align: bottom;
                            }
                        }
                        .swiper-slide:hover .ItemContent {
                          transform: translateY(-326px);
                          animation-play-state: running;
                        }
                        .swiper-slide:hover .itemTitles {
                          transform: translateY(60px);
                          animation-play-state: running;
                        }
                  }
                }
                .swiper-button-prev::after {
                  content: '';
                }
                .swiper-button-prev {
                    position: absolute;
                    background-color: #b89557;
                    div {
                      width: 30px;
                      height: 60px;
                      .arrowImg {
                        width: 30px;
                        height: 60px;
                      }
                    }
                }
                .swiper-button-next::after {
                  content: '';
                }
                .swiper-button-next {
                    position: absolute;
                    div {
                      width: 30px;
                      height: 60px;
                      img {
                        width: 30px;
                        height: 60px;
                      }
                    }
                }
              }
            }
            .business {
              padding: 0 50px;
              padding-bottom: 60px;
              .titles {
                padding: 0 0 40px 0;
                width: 100%;
                text-align: center;
                font-size: 36px;
                display: inline-block;
                background-image: linear-gradient(
                  to right,
                  #25AAE1,
                  #197DBE
                ); //最后三行为文字渐变CSS
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
              .business_content {
                display: flex;
                justify-content: space-between;
                .business_content_left {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  width: 572px;
                  .left_text {
                    text-indent: 2em;
                  }
                  div {
                    line-height: 34px;
                    font-size: 22px;
                  }
                }
                .business_content_right {
                  img{
                    width: 607px;
                    height: 461px;
                  }
                }
              }
            }
          }
          .third_content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .first_top{
              text-align: center;
              // margin-bottom: 35px;
              font-size: 36px;
              display: inline-block;
              background-image: linear-gradient(
                to right,
                #25a9e0,
                #187aba
              ); //最后三行为文字渐变CSS
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            .introduce {
              color: #666666;
              font-size: 30px;
              line-height: 50px;
              letter-spacing: 3px;
            }
            .question {
              padding: 85px 50px;
              display: flex;
              .lefts {
                height: 421px;
                .item {
                  // background: linear-gradient(to top right, #25aae1, #197dbe);
                  width: 638px;
                  height: 25%;
                  position: relative;
                  .itemOne {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 638px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    .img {
                      background: url('../../assets/images/industry/item1.png') no-repeat center center;
                      background-size: cover;
                      width: 82px;
                      height: 82px;
                      margin: 0 28px;
                    }
                    .text {
                      max-width: 500px;
                      font-size: 22px;
                      // color: #fff;
                      display: inline-block;
                      background-image: linear-gradient(
                        to right,
                        #25AAE1,
                        #197DBE
                      ); //最后三行为文字渐变CSS
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                }
                .item:hover {
                  background: linear-gradient(to top right, #25aae1, #197dbe);
                  .itemOne {
                    .img {
                      background: url('../../assets/images/industry/item1_selected.png') no-repeat center center;
                      background-size: cover;
                      transition: all 0.5s linear;
                    }
                    .text {
                      display: inline-block;
                      background-image: linear-gradient(
                        to right,
                        #fff,
                        #fff
                      ); //最后三行为文字渐变CSS
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                }
                .itemOnes {
                  // background: linear-gradient(to top right, #25aae1, #197dbe);
                  width: 638px;
                  height: 25%;
                  position: relative;
                  .itemOne {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 638px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    .img {
                      background: url('../../assets/images/industry/item1.png') no-repeat center center;
                      background-size: cover;
                      width: 82px;
                      height: 82px;
                      margin: 0 28px;
                    }
                    .text {
                      max-width: 500px;
                      font-size: 22px;
                      // color: #fff;
                      display: inline-block;
                      background-image: linear-gradient(
                        to right,
                        #25AAE1,
                        #197DBE
                      ); //最后三行为文字渐变CSS
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                }
                .itemOnes:hover {
                  background: linear-gradient(to top right, #25aae1, #197dbe);
                  .itemOne {
                    .img {
                      background: url('../../assets/images/industry/item2_selected.png') no-repeat center center;
                      background-size: cover;
                      transition: all 0.5s linear;
                    }
                    .text {
                      display: inline-block;
                      background-image: linear-gradient(
                        to right,
                        #fff,
                        #fff
                      ); //最后三行为文字渐变CSS
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                }
                .itemTwos {
                  // background: linear-gradient(to top right, #25aae1, #197dbe);
                  width: 638px;
                  height: 25%;
                  position: relative;
                  .itemOne {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 638px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    .img {
                      background: url('../../assets/images/industry/item3.png') no-repeat center center;
                      background-size: cover;
                      width: 82px;
                      height: 82px;
                      margin: 0 28px;
                    }
                    .text {
                      max-width: 500px;
                      font-size: 22px;
                      display: inline-block;
                      background-image: linear-gradient(
                        to right,
                        #25AAE1,
                        #197DBE
                      ); //最后三行为文字渐变CSS
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                }
                .itemTwos:hover {
                  background: linear-gradient(to top right, #25aae1, #197dbe);
                  .itemOne {
                    .img {
                      background: url('../../assets/images/industry/item3_selected.png') no-repeat center center;
                      background-size: cover;
                      transition: all 0.5s linear;
                    }
                    .text {
                      display: inline-block;
                      background-image: linear-gradient(
                        to right,
                        #fff,
                        #fff
                      ); //最后三行为文字渐变CSS
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                }
                .itemThrees {
                  // background: linear-gradient(to top right, #25aae1, #197dbe);
                  width: 638px;
                  height: 25%;
                  position: relative;
                  .itemThree {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 638px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    .img {
                      background: url('../../assets/images/industry/item3.png') no-repeat center center;
                      background-size: cover;
                      width: 82px;
                      height: 82px;
                      margin: 0 28px;
                    }
                    .text {
                      max-width: 500px;
                      font-size: 22px;
                      display: inline-block;
                      background-image: linear-gradient(
                        to right,
                        #25AAE1,
                        #197DBE
                      ); //最后三行为文字渐变CSS
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                }
                .itemThrees:hover {
                  background: linear-gradient(to top right, #25aae1, #197dbe);
                  .itemThree {
                    .img {
                      background: url('../../assets/images/industry/item3_selected.png') no-repeat center center;
                      background-size: cover;
                      transition: all 0.5s linear;
                    }
                    .text {
                      display: inline-block;
                      background-image: linear-gradient(
                        to right,
                        #fff,
                        #fff
                      ); //最后三行为文字渐变CSS
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                }
              }
              .rights {
                height: 423px;
                img {
                  height: 423px;
                }
              }
            }
            .solve {
              padding-bottom: 80px;
              .titles {
                padding: 20px 0 40px 0;
                width: 100%;
                text-align: center;
                font-size: 36px;
                display: inline-block;
                background-image: linear-gradient(
                  to right,
                  #25AAE1,
                  #197DBE
                ); //最后三行为文字渐变CSS
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
              .solveContent {
                position: relative;
                .swiper-container {
                  width: 1200px;
                  .swiper-wrapper {
                        width: 385px!important;
                        .swiper-slide  {
                          position: relative;
                          overflow: hidden!important;
                          cursor: pointer;
                          img {
                              width: 100%!important;
                              height: 326px;
                              vertical-align: bottom;
                          }
                          .itemTitles {
                              position: absolute;
                              height: 60px;
                              left: 0;
                              width: 100%;
                              margin-top: -60px;
                              text-align: center;
                              transition: all 0.15s linear;
                              background: linear-gradient(to right, #25a9e0, #187aba);
                              opacity: .9;
                              .itemTitle_title {
                                position: relative;
                                .titlesss{
                                  position: absolute;
                                  top: 0;
                                  left: 50%;
                                  transform: translateX(-50%);
                                  color: #fff;
                                  font-size: 18px;
                                  line-height: 60px;
                                }
                              }
                          }
                          .ItemContent {
                              margin-top: 4px;
                              transition: all 0.4s linear;
                              position: absolute;
                              width: 100%;
                              height: 326px;
                              .box {
                                font-size: 20px;
                                position: absolute;
                                color: #fff;
                                left: 0;
                                top: -4px;
                                width: 100%;
                                height: 330px;
                                background:rgba(37, 169, 224, 0.8);
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                .oneContent {
                                  padding: 0 20px;
                                  line-height: 34px;
                                }
                              }
                          }
                        }
                        .swiper-slide:hover .ItemContent {
                          transform: translateY(-326px);
                          animation-play-state: running;
                        }
                        .swiper-slide:hover .itemTitles {
                          transform: translateY(60px);
                          animation-play-state: running;
                        }
                  }
                }
                .swiper-button-prev::after {
                  content: '';
                }
                .swiper-button-prev {
                    position: absolute;
                    background-color: #b89557;
                    div {
                      width: 30px;
                      height: 60px;
                      .arrowImg {
                        width: 30px;
                        height: 60px;
                      }
                    }
                }
                .swiper-button-next::after {
                  content: '';
                }
                .swiper-button-next {
                    position: absolute;
                    div {
                      width: 30px;
                      height: 60px;
                      img {
                        width: 30px;
                        height: 60px;
                      }
                    }
                }
              }
            }
            .business {
              padding: 0 50px;
              padding-bottom: 60px;
              .titles {
                padding: 0 0 40px 0;
                width: 100%;
                text-align: center;
                font-size: 36px;
                display: inline-block;
                background-image: linear-gradient(
                  to right,
                  #25AAE1,
                  #197DBE
                ); //最后三行为文字渐变CSS
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
              .business_content {
                display: flex;
                justify-content: space-between;
                .business_content_left {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  width: 572px;
                  .left_text {
                    text-indent: 2em;
                  }
                  div {
                    line-height: 34px;
                    font-size: 22px;
                  }
                }
                .business_content_right {
                  img{
                    width: 607px;
                    height: 461px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .industry {
    padding-top: 0;
    .mobileProductTop {
      img {
        height: 4rem;
      }
    }
    .industry_content {
      .section {
        width: 100%;
        .el-tabs {
          .el-tabs__header {
            padding: 0;
            margin: 0 0 .5rem;
            line-height: 1.2rem;
            .el-tabs__nav-wrap {
              .el-tabs__nav-prev, .el-tabs__nav-next {
                display: none;
              }
              .el-tabs__nav-scroll {
                .el-tabs__nav{
                  .el-tabs__item {
                    font-size: .35rem;
                    padding: 0 .25rem;
                  }
                  #tab-1 {
                    padding-left: 0!important;
                  }
                  .el-tabs__active-bar {
                    height: 4px;
                    background: linear-gradient(to right, #25aae1, #197dbe);
                  }
                  .is-active {
                    display: inline-block;
                    background-image: linear-gradient(
                      to right,
                      #25a9e0,
                      #187aba
                    ); //最后三行为文字渐变CSS
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }
              }
            }
          }
          .el-tabs__content {
            .first_content {
              display: flex;
              flex-direction: column;
              justify-content: center;
              .first_top{
                margin-bottom: .3rem;
                font-weight: bold;
                font-size: .4rem;
              }
              .introduce {
                padding: 0;
                font-size: .3rem;
                line-height: .5rem;
                letter-spacing: 1px;
              }
              .question {
                padding:0 0 .5rem 0;
                display: flex;
                flex-direction: column;
                .lefts {
                  .item {
                    // background: linear-gradient(to top right, #25aae1, #197dbe);
                    width: 100%;
                    height: 1.5rem;
                    position: relative;
                    .itemOne {
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 1.5rem;
                      display: flex;
                      align-items: center;
                      .img {
                        background: url('../../assets/images/industry/item1.png') no-repeat center center;
                        background-size: cover;
                        width: 1.1rem;
                        height: 1.1rem;
                        margin: 0 .3rem 0 .2rem;
                      }
                      .text {
                        font-size: .35rem;
                        // color: #fff;
                        display: inline-block;
                        background-image: linear-gradient(
                          to right,
                          #25AAE1,
                          #197DBE
                        ); //最后三行为文字渐变CSS
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                      }
                    }
                  }
                  .itemOnes {
                    // background: linear-gradient(to top right, #25aae1, #197dbe);
                    width: 100%;
                    height: 1.5rem;
                    position: relative;
                    .itemOne {
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 1.5rem;
                      display: flex;
                      align-items: center;
                      .img {
                        background: url('../../assets/images/industry/item2.png') no-repeat center center;
                        background-size: cover;
                        width: 1.1rem;
                        height: 1.1rem;
                        margin: 0 .3rem 0 .2rem;
                      }
                      .text {
                        font-size: .35rem;
                        display: inline-block;
                        background-image: linear-gradient(
                          to right,
                          #25AAE1,
                          #197DBE
                        ); //最后三行为文字渐变CSS
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                      }
                    }
                  }
                  .itemTwos {
                    width: 100%;
                    height: 1.5rem;
                    position: relative;
                    .itemOne {
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 1.5rem;
                      display: flex;
                      align-items: center;
                      .img {
                        background: url('../../assets/images/industry/item3.png') no-repeat center center;
                        background-size: cover;
                        width: 1.1rem;
                        height: 1.1rem;
                        margin: 0 .3rem 0 .2rem;
                      }
                      .text {
                        font-size: .35rem;
                        // color: #fff;
                        display: inline-block;
                        background-image: linear-gradient(
                          to right,
                          #25AAE1,
                          #197DBE
                        ); //最后三行为文字渐变CSS
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                      }
                    }
                  }
                }
                .rights {
                  height: 3.2rem;
                  img {
                    height: 3.2rem;
                  }
                }
              }
              .solve {
                padding-bottom: .5rem;
                .titles {
                  padding: 0 0 .3rem 0;
                  width: 100%;
                  font-weight: bold;
                  font-size: 0.4rem;
                }
                .solveContent {
                  // position: relative;
                  padding: 0 .5rem;
                  .swiper-container {
                    width: 100%;
                    .swiper-wrapper {
                          width: 100%!important;
                          .swiper-slide  {
                            position: relative;
                            overflow: hidden!important;
                            cursor: pointer;
                            img {
                                width: 3rem!important;
                                height: 2.8rem;
                                vertical-align: bottom;
                            }
                            .itemTitles {
                                position: absolute;
                                height: .8rem;
                                left: 0;
                                width: 100%;
                                margin-top: -.8rem;
                                .itemTitle_title {
                                  position: relative;
                                  .titlesss{
                                    position: absolute;
                                    top: 0;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    color: #fff;
                                    font-size: .3rem;
                                    line-height: .8rem;
                                  }
                                }
                            }
                            .ItemContent {
                                margin-top: 4px;
                                transition: all 0.4s linear;
                                position: absolute;
                                width: 3rem;
                                height: 2.8rem;
                                .box {
                                  font-size: .28rem;
                                  position: absolute;
                                  color: #fff;
                                  left: 0;
                                  top: -4px;
                                  width: 100%;
                                  height: 3rem;
                                  .oneContent {
                                    padding: 0 .2rem;
                                    line-height: .5rem;
                                  }
                                }
                            }
                          }
                          .swiper-slide:hover .ItemContent {
                            transform: translateY(-2.8rem);
                            animation-play-state: running;
                          }
                          .swiper-slide:hover .itemTitles {
                            transform: translateY(.8rem);
                            animation-play-state: running;
                          }
                    }
                  }
                  .swiper-button-prev::after {
                    content: '';
                  }
                  .swiper-button-prev {
                      position: absolute;
                      top: 54%;
                      left: 0;
                      width: .4rem;
                      height: .8rem;
                      transform: translateY(50%);
                      div {
                        width: .4rem;
                        height: .8rem;
                        .arrowImg {
                          width: .4rem;
                          height: .8rem;
                        }
                      }
                  }
                  .swiper-button-next::after {
                    content: '';
                  }
                  .swiper-button-next {
                      position: absolute;
                      top: 54%;
                      right: 0;
                      width: .4rem;
                      height: .8rem;
                      transform: translateY(50%);
                      div {
                        width: .4rem;
                        height: .8rem;
                        .arrowImg {
                          width: .4rem;
                          height: .8rem;
                        }
                      }
                  }
                }
              }
              .business {
                padding: 0 ;
                padding-bottom: .5rem;
                .titles {
                  padding: 0 0 .3rem 0;
                  width: 100%;
                  font-weight: bold;
                  font-size: 0.4rem;
                }
                .business_content {
                  display: flex;
                  flex-direction: column;
                  .business_content_left {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    .left_text {
                      text-indent: 2em;
                      font-size: .3rem;
                    }
                    div {
                      line-height: .5rem;
                      font-size: .3rem;
                    }
                  }
                  .business_content_right {
                    padding: .3rem;
                    img{
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
              }
            }
            .second_content {
              display: flex;
              flex-direction: column;
              justify-content: center;
              .first_top{
                margin-bottom: .3rem;
                font-weight: bold;
                font-size: .4rem;
              }
              .introduce {
                padding: 0;
                font-size: .3rem;
                line-height: .5rem;
                letter-spacing: 1px;
              }
              .question {
                padding:0 0 .5rem 0;
                display: flex;
                flex-direction: column;
                .lefts {
                  height: 100%;
                  .item {
                    // background: linear-gradient(to top right, #25aae1, #197dbe);
                    width: 100%;
                    height: 1.5rem;
                    position: relative;
                    .itemOne {
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 1.5rem;
                      display: flex;
                      align-items: center;
                      .img {
                        background: url('../../assets/images/industry/item1.png') no-repeat center center;
                        background-size: cover;
                        width: 1rem;
                        height: 1rem;
                        margin: 0 .3rem 0 .2rem;
                      }
                      .text {
                        max-width: 5.5rem;
                        font-size: .35rem;
                        // color: #fff;
                        display: inline-block;
                        background-image: linear-gradient(
                          to right,
                          #25AAE1,
                          #197DBE
                        ); //最后三行为文字渐变CSS
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                      }
                    }
                  }
                  .itemOnes {
                    // background: linear-gradient(to top right, #25aae1, #197dbe);
                    width: 100%;
                    height: 1.5rem;
                    position: relative;
                    .itemOne {
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 1.5rem;
                      display: flex;
                      align-items: center;
                      .img {
                        background: url('../../assets/images/industry/item2.png') no-repeat center center;
                        background-size: cover;
                        width: 1rem;
                        height: 1rem;
                        margin: 0 .3rem 0 .2rem;
                      }
                      .text {
                        max-width: 5.5rem;
                        font-size: .35rem;
                        display: inline-block;
                        background-image: linear-gradient(
                          to right,
                          #25AAE1,
                          #197DBE
                        ); //最后三行为文字渐变CSS
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                      }
                    }
                  }
                  .itemTwos {
                    // background: linear-gradient(to top right, #25aae1, #197dbe);
                    width: 100%;
                    height: 1.5rem;
                    position: relative;
                    .itemOne {
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 1.5rem;
                      display: flex;
                      align-items: center;
                      .img {
                        background: url('../../assets/images/industry/item3.png') no-repeat center center;
                        background-size: cover;
                        width: 1rem;
                        height: 1rem;
                        margin: 0 .3rem 0 .2rem;
                      }
                      .text {
                        max-width: 5.5rem;
                        font-size: .35rem;
                        display: inline-block;
                        background-image: linear-gradient(
                          to right,
                          #25AAE1,
                          #197DBE
                        ); //最后三行为文字渐变CSS
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                      }
                    }
                  }
                  .itemThrees {
                    // background: linear-gradient(to top right, #25aae1, #197dbe);
                    width: 100%;
                    height: 1.5rem;
                    position: relative;
                    .itemThree {
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 1.5rem;
                      display: flex;
                      align-items: center;
                      .img {
                        background: url('../../assets/images/industry/item3.png') no-repeat center center;
                        background-size: cover;
                        width: 1rem;
                        height: 1rem;
                        margin: 0 .3rem 0 .2rem;
                      }
                      .text {
                         max-width: 5.5rem;
                        font-size: .35rem;
                        display: inline-block;
                        background-image: linear-gradient(
                          to right,
                          #25AAE1,
                          #197DBE
                        ); //最后三行为文字渐变CSS
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                      }
                    }
                  }
                }
                .rights {
                  height: 3.2rem;
                  img {
                    height: 3.2rem;
                  }
                }
              }
              .solve {
                padding-bottom: .5rem;
                .titles {
                  padding: 0 0 .3rem 0;
                  width: 100%;
                  font-weight: bold;
                  font-size: 0.4rem;
                }
                .solveContent {
                  // position: relative;
                  padding: 0 .5rem;
                  .swiper-container {
                    width: 100%;
                    .swiper-wrapper {
                          width: 100%!important;
                          .swiper-slide  {
                            position: relative;
                            overflow: hidden!important;
                            cursor: pointer;
                            img {
                                width: 3rem!important;
                                height: 2.8rem;
                                vertical-align: bottom;
                            }
                            .itemTitles {
                                position: absolute;
                                height: .8rem;
                                left: 0;
                                width: 100%;
                                margin-top: -.8rem;
                                .itemTitle_title {
                                  position: relative;
                                  .titlesss{
                                    position: absolute;
                                    top: 0;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    color: #fff;
                                    font-size: .3rem;
                                    line-height: .8rem;
                                  }
                                }
                            }
                            .ItemContent {
                                margin-top: 4px;
                                transition: all 0.4s linear;
                                position: absolute;
                                width: 3rem;
                                height: 2.8rem;
                                .box {
                                  font-size: .28rem;
                                  position: absolute;
                                  color: #fff;
                                  left: 0;
                                  top: -4px;
                                  width: 100%;
                                  height: 3rem;
                                  .oneContent {
                                    padding: 0 .2rem;
                                    line-height: .5rem;
                                  }
                                }
                            }
                          }
                          .swiper-slide:hover .ItemContent {
                            transform: translateY(-2.8rem);
                            animation-play-state: running;
                          }
                          .swiper-slide:hover .itemTitles {
                            transform: translateY(.8rem);
                            animation-play-state: running;
                          }
                    }
                  }
                  .swiper-button-prev::after {
                    content: '';
                  }
                  .swiper-button-prev {
                      position: absolute;
                      top: 54%;
                      left: 0;
                      width: .4rem;
                      height: .8rem;
                      transform: translateY(0);
                      div {
                        width: .4rem;
                        height: .8rem;
                        .arrowImg {
                          width: .4rem;
                          height: .8rem;
                        }
                      }
                  }
                  .swiper-button-next::after {
                    content: '';
                  }
                  .swiper-button-next {
                      position: absolute;
                      top: 54%;
                      right: 0;
                      width: .4rem;
                      height: .8rem;
                      transform: translateY(0);
                      div {
                        width: .4rem;
                        height: .8rem;
                        .arrowImg {
                          width: .4rem;
                          height: .8rem;
                        }
                      }
                  }
                }
              }
              .business {
                padding: 0 ;
                padding-bottom: .5rem;
                .titles {
                  padding: 0 0 .3rem 0;
                  width: 100%;
                  font-weight: bold;
                  font-size: 0.4rem;
                }
                .business_content {
                  display: flex;
                  flex-direction: column;
                  .business_content_left {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    .left_text {
                      text-indent: 2em;
                      font-size: .3rem;
                    }
                    div {
                      line-height: .5rem;
                      font-size: .3rem;
                    }
                  }
                  .business_content_right {
                    padding: .3rem;
                    img{
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
              }
            }
            .third_content {
              display: flex;
              flex-direction: column;
              justify-content: center;
              .first_top{
                margin-bottom: .3rem;
                font-weight: bold;
                font-size: .4rem;
              }
              .introduce {
                padding: 0;
                font-size: .3rem;
                line-height: .5rem;
                letter-spacing: 1px;
              }
              .question {
                padding:0 0 .5rem 0;
                display: flex;
                flex-direction: column;
                .lefts {
                  height: 100%;
                  .item {
                    // background: linear-gradient(to top right, #25aae1, #197dbe);
                    width: 100%;
                    height: 1.5rem;
                    position: relative;
                    .itemOne {
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 1.5rem;
                      display: flex;
                      align-items: center;
                      .img {
                        background: url('../../assets/images/industry/item1.png') no-repeat center center;
                        background-size: cover;
                        width: 1rem;
                        height: 1rem;
                        margin: 0 .3rem 0 .2rem;
                      }
                      .text {
                        max-width: 5.5rem;
                        font-size: .35rem;
                        // color: #fff;
                        display: inline-block;
                        background-image: linear-gradient(
                          to right,
                          #25AAE1,
                          #197DBE
                        ); //最后三行为文字渐变CSS
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                      }
                    }
                  }
                  .itemOnes {
                    // background: linear-gradient(to top right, #25aae1, #197dbe);
                    width: 100%;
                    height: 1.5rem;
                    position: relative;
                    .itemOne {
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 1.5rem;
                      display: flex;
                      align-items: center;
                      .img {
                        background: url('../../assets/images/industry/item2.png') no-repeat center center;
                        background-size: cover;
                        width: 1rem;
                        height: 1rem;
                        margin: 0 .3rem 0 .2rem;
                      }
                      .text {
                        max-width: 5.5rem;
                        font-size: .35rem;
                        display: inline-block;
                        background-image: linear-gradient(
                          to right,
                          #25AAE1,
                          #197DBE
                        ); //最后三行为文字渐变CSS
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                      }
                    }
                  }
                  .itemTwos {
                    // background: linear-gradient(to top right, #25aae1, #197dbe);
                    width: 100%;
                    height: 1.5rem;
                    position: relative;
                    .itemOne {
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 1.5rem;
                      display: flex;
                      align-items: center;
                      .img {
                        background: url('../../assets/images/industry/item3.png') no-repeat center center;
                        background-size: cover;
                        width: 1rem;
                        height: 1rem;
                        margin: 0 .3rem 0 .2rem;
                      }
                      .text {
                        max-width: 5.5rem;
                        font-size: .35rem;
                        display: inline-block;
                        background-image: linear-gradient(
                          to right,
                          #25AAE1,
                          #197DBE
                        ); //最后三行为文字渐变CSS
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                      }
                    }
                  }
                  .itemThrees {
                    // background: linear-gradient(to top right, #25aae1, #197dbe);
                    width: 100%;
                    height: 1.5rem;
                    position: relative;
                    .itemThree {
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 1.5rem;
                      display: flex;
                      align-items: center;
                      .img {
                        background: url('../../assets/images/industry/item3.png') no-repeat center center;
                        background-size: cover;
                        width: 1rem;
                        height: 1rem;
                        margin: 0 .3rem 0 .2rem;
                      }
                      .text {
                         max-width: 5.5rem;
                        font-size: .35rem;
                        display: inline-block;
                        background-image: linear-gradient(
                          to right,
                          #25AAE1,
                          #197DBE
                        ); //最后三行为文字渐变CSS
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                      }
                    }
                  }
                }
                .rights {
                  height: 3.2rem;
                  img {
                    height: 3.2rem;
                  }
                }
              }
              .solve {
                padding-bottom: .5rem;
                .titles {
                  padding: 0 0 .3rem 0;
                  width: 100%;
                  font-weight: bold;
                  font-size: 0.4rem;
                }
                .solveContent {
                  // position: relative;
                  padding: 0 .5rem;
                  .swiper-container {
                    width: 100%;
                    .swiper-wrapper {
                          width: 100%!important;
                          .swiper-slide  {
                            position: relative;
                            overflow: hidden!important;
                            cursor: pointer;
                            img {
                                width: 3rem!important;
                                height: 2.8rem;
                                vertical-align: bottom;
                            }
                            .itemTitles {
                                position: absolute;
                                height: .8rem;
                                left: 0;
                                width: 100%;
                                margin-top: -.8rem;
                                .itemTitle_title {
                                  position: relative;
                                  .titlesss{
                                    position: absolute;
                                    top: 0;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    color: #fff;
                                    font-size: .3rem;
                                    line-height: .8rem;
                                  }
                                }
                            }
                            .ItemContent {
                                margin-top: 4px;
                                transition: all 0.4s linear;
                                position: absolute;
                                width: 3rem;
                                height: 2.8rem;
                                .box {
                                  font-size: .28rem;
                                  position: absolute;
                                  color: #fff;
                                  left: 0;
                                  top: -4px;
                                  width: 100%;
                                  height: 3rem;
                                  .oneContent {
                                    padding: 0 .2rem;
                                    line-height: .5rem;
                                  }
                                }
                            }
                          }
                          .swiper-slide:hover .ItemContent {
                            transform: translateY(-2.8rem);
                            animation-play-state: running;
                          }
                          .swiper-slide:hover .itemTitles {
                            transform: translateY(.8rem);
                            animation-play-state: running;
                          }
                    }
                  }
                  .swiper-button-prev::after {
                    content: '';
                  }
                  .swiper-button-prev {
                      position: absolute;
                      top: 54%;
                      left: 0;
                      width: .4rem;
                      height: .8rem;
                      transform: translateY(0);
                      div {
                        width: .4rem;
                        height: .8rem;
                        .arrowImg {
                          width: .4rem;
                          height: .8rem;
                        }
                      }
                  }
                  .swiper-button-next::after {
                    content: '';
                  }
                  .swiper-button-next {
                      position: absolute;
                      top: 54%;
                      right: 0;
                      width: .4rem;
                      height: .8rem;
                      transform: translateY(0);
                      div {
                        width: .4rem;
                        height: .8rem;
                        .arrowImg {
                          width: .4rem;
                          height: .8rem;
                        }
                      }
                  }
                }
              }
              .business {
                padding: 0 ;
                padding-bottom: .5rem;
                .titles {
                  padding: 0 0 .3rem 0;
                  width: 100%;
                  font-weight: bold;
                  font-size: 0.4rem;
                }
                .business_content {
                  display: flex;
                  flex-direction: column;
                  .business_content_left {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    .left_text {
                      text-indent: 2em;
                      font-size: .3rem;
                    }
                    div {
                      line-height: .5rem;
                      font-size: .3rem;
                    }
                  }
                  .business_content_right {
                    padding: .3rem;
                    img{
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>